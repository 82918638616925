:root {
  --bpj-lightblue : #526584;
  --bpj-blue : #021524;
  --bpj-deepblue : #010213;
}

::selection {
  background-color:#FFF;
  color:var(--bpj-blue);
}

@font-face {
  font-family: 'Pretendard';
  src: url('/src/assets/fonts/PretendardVariable.woff2');
}

html, body, #root {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

body {
  background: var(--bpj-blue);
  font-family: 'Pretendard';
  font-size:1.0em;
  font-weight: 300;
}

a {
  text-decoration: none;
  color:inherit;
  cursor: pointer;
}

li {
  list-style: none;
}

input {
  outline: none;
  border:none;
}

h1,h2,h3,h4,h5,h6,p {
  margin:5px auto;
}
h1,h2,h3,h4,h5,h6,b,strong {
  font-weight: 600;
}

section, header, footer, nav {
  box-sizing: border-box;
  max-width: 100%;
}

.wrapper {
  max-width: 1200px;
  margin: auto;
}


#site-container {
  height:100%;
  display: grid;
  grid-template-rows: 80px auto 80px;
  color:#FFF;
}

#site-header, #site-footer, #site-navigation {
  padding:0 30px;
  display: flex;
  align-items: center;
  max-width:100%;
}

#site-header {
  justify-content: center;
}

#site-footer {
  justify-content: space-between;
  background:linear-gradient(var(--bpj-blue), var(--bpj-deepblue));
}

#site-footer #copyright {
  font-size:0.8em;
}

#site-navigation a {
  margin:auto 5px;
}


.between {
  display: flex;
  justify-content: space-between;
}

.width-fill {
  width:100%;
  box-sizing: border-box;
}

.text-center {
  text-align: center;
}

.small {
  font-size:0.8em;
}

.section-header {
  font-size:1.2em;
  font-weight: 500;
  text-align: center;
}

.zero-height {
  line-height: 0;
}

#slider {
  position: absolute;
  z-index: -1;
  display: flex;
  min-height:300px;
  left:0;
  transition: left 0.3s;
  justify-content: center;
  align-items: center;
  height:100%;
}

#slider>div {
  width:100%;
  height:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;  
}

#slider>div>.shade {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(2, 21, 36, 0.4);
  padding:30px;
}

#slider_control {
  user-select: none;
}

#slider_control>a {
  position: absolute;
  padding:10px;
  font-size: 0.9em;
}

#slider_control>a#prev {
  left:0;
}

#slider_control>a#next {
  right:0;
}

#slider_container {
  width:100%;
  min-height:300px;
  overflow:hidden;
  display: flex;
  align-items: center;
  position: relative;
}

#projects {
  display:flex;
  max-width:1200px;
  margin:50px auto;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

#projects > div {
  border:#FFF 1px solid;
  width:100%;
  max-width: 320px;
  padding:20px;
  margin:10px;
  height:100%;
  min-height:200px;
  display: grid;
  grid-template-rows: 180px 40px 60px 40px;
}

#projects > div > .image {
  background-color:var(--bpj-deepblue);
}

#projects > div > .image > img {
  width:100%;
  height:100%;
  object-fit: cover;
}

#projects > div > * {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tags {
  font-size: 0.9em;
}

.tags > span {  
  border: #FFF 1px solid;
  padding:2px 7px;
  border-radius: 0px;
  margin:2px;
  display: inline-block;
}

#vision {
  margin: 20px auto;
  padding:30px;
  word-break: keep-all;
}

#topics {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin:auto;
  grid-gap: 10px;
}

#topics>div {
  padding:5px 20px;
  font-weight: 500;
  text-align: center;
  background-color: #EDEDEC;
  color:var(--bpj-blue);
}

.staff {
  height:100px;
  width:100px;
  background-color:transparent;
  color: var(--bpj-lightblue);
  box-shadow: 1px 1px 10px 1px var(--bpj-deepblue);
  font-size:0.8em;
  display:flex;
  align-items:center;
  justify-content:center;
  border-radius: 20px;
  margin:10px;
}

#papers {
  display:flex;
  margin:auto;
  justify-content:center;
  flex-wrap: wrap;
}

.paper {
  background-color:#FFF;
  width:33.33%;
  max-width: 240px;
  color:#010213;
  padding:10px 20px;
  margin:10px;
}
.paper>h5 {
  height:20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size:1.0em;
}

#contact input {
  padding:5px;
  margin:5px auto;
}

#social a {
  margin:10px;
  font-size:0.8em;
}

@media screen and (max-width:500px) {
  body {font-size: 0.9em;}

  #topics {
    grid-template-columns: 1fr;
  }
}